// controllers/switch_controller.js
import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ["switch","textinput","badge","selectmultiple","list"]

    connect(){
        this.toggleSelect();
    }

    clean_text_input(){
        this.textinputTarget.value = "";
        this.badgeTarget.remove();
   }

    toggleSelect() {
    if (this.switchTarget.checked) {
        this.selectmultipleTarget.classList.remove("d-none");
    } else {
        this.selectmultipleTarget.classList.add("d-none");
    }
    }

}

