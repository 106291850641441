import { Controller } from "stimulus";

export default class extends Controller {
    connect() {
        this.checkbox = this.element.querySelector("input[type=checkbox]");
        this.inputs = this.element.querySelector(".input_container");

        // Set initial visibility of inputs
        this.toggle(this.checkbox);

        // Add change event listener to the checkbox
        this.checkbox.addEventListener("change", () => this.toggle(this.checkbox));
    }

    toggle(target) {
        this.inputs.style.display = target.checked ? "block" : "none";
    }
}
