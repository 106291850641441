import { Controller } from "stimulus";
import Choices from "choices.js";

export default class extends Controller {
    static targets = ["selector"];
    
    static values = {
        placeholder: String
      }

    connect() {
        const options = {
            removeItemButton: true,
            itemSelectText: "",
            noChoicesText: "no hay opciones para elegir",
            noResultsText: "no se encontraron resultados"
        };

        if (this.placeholderValue) {
            options.placeholder = true;
            options.placeholderValue = this.placeholderValue;
        }

        new Choices(this.selectorTargets[0], options);
    }
}