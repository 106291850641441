import { Controller } from "stimulus";
import {createApp} from "vue";
import ModalConfirm from "../components/qualification_processes/ModalConfirm.vue";

export default class extends Controller {
    static targets = ["addUserSubmit", "modal", "userRutInput", "spinner"]
    static values = {
        confirmTitle: String,
        confirmDescription: String,
        userRut: String,
        searchUrl: String,
        userId: Number,
        serviceSuccess: Boolean,
        closeText: String,
        continue: String
    
    }

    connect() {
        this.disableForm()
    }


    submit(event){
        const modalApp = createApp(ModalConfirm, {
            title: this.confirmTitleValue,
            description: this.confirmDescriptionValue,
            user: event?.params?.user,
            valueNotFound: event?.params?.valueNotFound,
            locale: event?.params?.locale,
            closeText: this.closeTextValue,
            continue: this.continueValue
        });

        modalApp.mount(this.modalTarget);
        this.modalTarget.querySelector('.uc-modal').style.display = 'block';
    }

    disableForm() {        
        if (this.serviceSuccessValue !== true){
            this.addUserSubmitTarget.disabled = false
        } else {
            this.addUserSubmitTarget.disabled = true
        }

        if (this.userRutInputTarget.value != this.userRutValue){
            this.addUserSubmitTarget.disabled = true
        }
      
        if (this.userRutInputTarget.value === this.userRutValue){
            this.addUserSubmitTarget.disabled = false
        }

        const strategies = {
            [(this.userRutInputTarget.value.replace(/[.\-]/g, '')  != this.userRutValue)] : true,
            [(this.serviceSuccessValue === false)]: true
        }

        this.addUserSubmitTarget.disabled = strategies[true] || false
    }

    searchByRut() {
        this.spinnerTarget.classList.toggle('d-none');
        const inputValue = this.userRutInputTarget?.value?.replace(/[.\-]/g, '');
        const url = new URL(this.searchUrlValue);
        url.searchParams.set("rut", inputValue);

        window.location.href = url.toString();
    }
}
